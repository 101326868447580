import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Author } from "../../../components/AuthorBlog/Author";
import { Link } from "../../../components/Link/Link";
import { Text } from "../../../components/Text/Text";
import { color, typography } from "../../../shared/style";
import {redirectPage} from "../../../hooks/redirect";
// import { RichText } from "prismic-reactjs";
// import htmlSerializer from "./RichtextHelper/CustomText";
export const ItemBlog = ({ data, uid, open_new_tab }) => {
  // console.log(data);
  const RedirectDetail = () => {
    window.location.href = `/${uid}`;
  };
  if (!data || !uid) return null;
  return (
    <WrapperSide>
      <WrapperItemImage onClick={() => redirectPage({
          link_type: open_new_tab==true ? "Web" : "Document",
          type: uid,
          url: `https://draperstartuphouse.com/${uid}`,
          uid: uid
        })}>
        <Image src={data?.image?.url} />
      </WrapperItemImage>
      <WrapperItemTitle onClick={() => redirectPage({
          link_type: open_new_tab==true ? "Web" : "Document",
          type: uid,
          url: `https://draperstartuphouse.com/${uid}`,
          uid: uid
        })}>
        <Title
          font={typography.type.primary}
          weight={typography.weight.bold}
          color={color.netraul.black100}
        >
          {data.title.text}
        </Title>
      </WrapperItemTitle>
      <Author
        onClick={() => redirectPage({
          link_type: open_new_tab==true ? "Web" : "Document",
          type: uid,
          url: `https://draperstartuphouse.com/${uid}`,
          uid: uid
        })}
        isContent
        data={{
          date: data.release_date,
          name: data?.author?.text,
        }}
      />
      <WrapperBtnItem>
        <BtnItem onClick={() => redirectPage({
          link_type: open_new_tab==true ? "Web" : "Document",
          type: uid,
          url: `https://draperstartuphouse.com/${uid}`,
          uid: uid
        })}>Read more</BtnItem>
      </WrapperBtnItem>
    </WrapperSide>
  );
};

ItemBlog.propTypes = {
  title: PropTypes.string,
};

const WrapperSide = styled.div`
  cursor: pointer;
  width: calc((100% / 3) - 80px / 3);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 650px) {
    height: 100%;
    width: 100%;
  }
  @media (min-width: 651px) and (max-width: 1000px) {
    width: calc((100% / 3) - 40px / 3);
  }
  &:hover {
  }
`;
const WrapperBtnItem = styled.div`
  margin-top: 16px;
`;
const BtnItem = styled(Link)`
  color: ${color.primary.blue};
  text-decoration: underline;
  text-underline-offset: 5px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
`;
const WrapperItemImage = styled.div`
  width: 100%;
  height: 250px;
  margin-bottom: 24px;
  @media (max-width: 650px) {
    height: 217px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const WrapperItemTitle = styled.div`
  margin: 0px 0 16px;
`;
const Title = styled(Text)`
  cursor: pointer;
  letter-spacing: -0.015em;
  @media (max-width: 650px) {
    font-size: 20px !important;
    line-height: 30px;
  }
`;
