import React from 'react';
import 'react-mailchimp-email-signup-form/dist/esm/index.css';
import { ReactMailchimpEmailSignupForm } from './ReactMailchimpEmailSignupForm';


// Here's a usage example when using functional components
export const SubscribeForm = ({ title, description, button }) => (
    
    <ReactMailchimpEmailSignupForm
        elementId="first-email-signup-form"
        url={process.env.GATSBY_MAILCHIMP_URL}
        title={title?.text}
        subtitle={description?.text}
        labelBtn={button?.text}
    />
);
