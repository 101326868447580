import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { color, typography } from "../../shared/style";
import snake from "../../images/snake.gif";
import search from "../../images/search.svg";
import PropTypes from "prop-types";
import { isMobile } from "../../hooks/checkMobile";
import { BlogTags } from "./Blogs/Tags";
import { Categories } from "./Blogs/Categories";
import { ItemBlog } from "./Blogs/ItemBlog";

import { useDebounce } from '../../hooks/useDebounce';
import { Text } from "../../components/Text/Text";
import { Button } from "../../components/Button/Button";

export const Recent = ({ tags, data, allBlog }) => {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(9);
  const [isEnd, setIsEnd] = useState(false);
  const [listData, setListData] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [dataShow, setDataShow] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const debouncedValue = useDebounce(valueSearch, 800);
  const categories = data?.items;
  useEffect(() => {
    const datas = [];
    categories && categories.forEach((item) => {
      item.category.document.data.posts.forEach((ite) => {
        datas.push(ite);
      });
    });
    setDataFiltered(allBlog);
    setListData(allBlog); // map data from list
    setData(allBlog); // update every page changed
    //eslint-disable-next-line
  }, [data.items, allBlog]);

  useEffect(() => {
    setData(dataFiltered); // update every page changed
    //eslint-disable-next-line
  }, [page, dataFiltered]);

  const onSelectedCategory = (item, isAll) => {
    setPage(1)
    isAll ? setData(allBlog) : setData(item.category.document.data.posts, true)
  }
  useEffect(() => {
    let dataFilter = []
    if (debouncedValue) {
      setPage(1)
      dataFilter = dataFiltered.filter((item) => {
        return item?.post?.document?.data?.title?.text.toLowerCase().includes(debouncedValue.toLowerCase()) || item?.data?.title?.text.toLowerCase().includes(debouncedValue.toLowerCase())
      })
      setData(dataFilter, true)
    } else setData(listData)
    //eslint-disable-next-line
  }, [debouncedValue,dataFiltered, listData])

  const setData = (data, force) => {
    let datas = [];
    let size = isMobile ? 3 : pageSize
    if (data.length || force) {
      datas = data.slice(0, page * size);
      setDataShow(datas);
      setIsEnd(page * size >= data.length)
    }
  };
  const onLoadMore = () => {
    setLoading(true)
    setTimeout(() => {
      setPage(page + 1);
      setLoading(false)
    }, 500);
  };
  if (!tags || !data.items || !data || !allBlog) return null;
  return (
    <WrapperSection>
      {/* <WrapperTitle>
        <Title
          tag={"h4"}
          weight={typography.weight.regular}
          capital="uppercase"
          color={color.primary.blue}
        >
          {data?.primary?.title1?.text}
        </Title>
      </WrapperTitle> */}
      <WrapperHead>
        {/* <WrapperTag>
          <Label
            weight={typography.weight.bold}
            font={typography.type.primary}
            color={color.netraul.black100}
          >
            Tags
          </Label>
          <BlogTags tags={tags} />
        </WrapperTag> */}
        <WrapperSearch>
          <Label
            search
            weight={typography.weight.bold}
            font={typography.type.primary}
            color={color.netraul.black100}
          >
            Search
          </Label>
          <SearchBar>
            <InputSearch
              placeholder="Search..."
              onChange={(e) => setValueSearch(e.target.value)}
            />
            <Icon src={search} />
          </SearchBar>
        </WrapperSearch>
      </WrapperHead>
      <WrapperCategory>
        <Categories
          categories={categories}
          onSelectedCategory={onSelectedCategory}
        />
      </WrapperCategory>
      <WrapperItem>
        {dataShow.length ? (
          dataShow.map((ite, i) => (
            <ItemBlog
              key={i}
              data={ite?.post?.document?.data || ite?.data}
              uid={ite?.post?.uid || ite?.uid}
              open_new_tab={false}
            />
          ))
        ) : (
          <NoData>No Data</NoData>
        )}
      </WrapperItem>
      {dataShow.length && !isEnd ? (
        <WrapperButton>
          <WrapperLoading>
            {isLoading && <Loading src={snake} />}
          </WrapperLoading>
          <Btn type="primary" onClick={() => onLoadMore()}>
            Load more
          </Btn>
        </WrapperButton>
      ) : null}
      <WrapperTag forMobile={true}>
        <Label
          weight={typography.weight.bold}
          font={typography.type.primary}
          color={color.netraul.black100}
        >
          Tags
        </Label>
        <BlogTags tags={tags} />
      </WrapperTag>
    </WrapperSection>
  );
};

Recent.propTypes = {
  title: PropTypes.string,
};
const NoData = styled.div`
  margin: 0 auto;
`
const WrapperLoading = styled.div`
  height: 30px;
  margin-top: 10px;
`
const Loading = styled.img`
  width: 30px;
`
const WrapperButton = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`;
const Btn = styled(Button)`
  border-radius: 40px;
  padding: 20px 45px;
  line-height: 26px;
  width: fit-content;
  margin-top: 30px;
  @media (max-width: 650px) {
    margin-top: 40px;
  }
`;
const Icon = styled.img`
  width: 14px;
  height: 14px;
  right: 25px;
  top: 50%;
  position: absolute;
`;
const WrapperHead = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 650px) {
    margin-top: 40px;
  }
`;
const WrapperTag = styled.div`
  width: 52.5%;
  display: ${props => props.forMobile ? "none" : "flex"};
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
    margin: 20px 0;
  }
  @media (max-width: 650px) {
    display: ${props => props.forMobile ? "flex" : "none"};
    margin-top: 40px;
  }
`;
const WrapperSearch = styled.div`
  display: flex;
  flex-direction: column;
  width: 47.5%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const WrapperCategory = styled.div``;
const WrapperItem = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  @media (max-width: 1000px) and (min-width: 651px) {
    gap: 20px;
  }
`;
const WrapperTitle = styled.div`
  margin-bottom: 30px;
`;
const Title = styled(Text)`
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px !important;
  }
`;
const Label = styled(Text)`
  @media (min-width: 1000px) {
    padding-left: ${props => props.search && "22px"};
  }
`;

const InputSearch = styled.input`
  margin-top: 12px;
  width: 100%;
  border: 1px solid #d3d3d4;
  border-radius: 50px;
  background-color: white;
  padding: 19px 50px 19px 22px;
  outline: none;
  font-size: 16px;
  box-sizing: border-box;
  line-height: 26px;
`;
const SearchBar = styled.div`
  position: relative;
  width: 100%;
`;

const WrapperSection = styled.div`
  padding: 0 130px;
  padding-top: 80px !important;
  max-width: 1500px;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    padding: 0 40px;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
