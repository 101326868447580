import { useStaticQuery, graphql } from "gatsby"

export const useQueryBlog = () => {
  const { allPrismicBlog, allPrismicBlogDetail } = useStaticQuery(
    graphql`
      query QueryBlog {
        allPrismicBlog {
          edges {
            node {
              data {
                title {
                  html
                  text
                  raw
                }
                stories_and_news {
                  post {
                    document {
                      ... on PrismicBlogDetail {
                        data {
                          image {
                            url
                          }
                          title {
                            html
                            text
                            raw
                          }    
                          author {
                            html
                            text
                            raw
                          }
                          open_new_tab
                          release_date
                          body {
                            ... on PrismicBlogDetailBodyText {
                              primary {
                                text {
                                  html
                                  text
                                  raw
                                }
                              }
                            }
                          }
                        }
                        uid
                      }
                    }
                  }
                }
                body {
                  ... on PrismicBlogBodyRecentPosts {
                    primary {
                      title1 {
                        html
                        text
                        raw
                      }
                    }
                    items {
                      category {
                        document {
                          ... on PrismicBlogCategories {
                            data {
                              category_name {
                                text
                              }
                              posts {
                                post {
                                  document {
                                    ... on PrismicBlogDetail {
                                      data {
                                        image {
                                          url
                                        }
                                        title {
                                          html
                                          text
                                          raw
                                        }
                                        author {
                                          text
                                          html
                                          raw
                                        }
                                        open_new_tab
                                        release_date
                                      }
                                    }
                                  }
                                  uid
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                body2 {
                  ... on PrismicBlogBody2GeneralCard {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      robot {
                        html
                        text
                        raw
                      }
                      image {
                        url
                        alt
                      }
                      googlebot {
                        html
                        text
                        raw
                      }
                      favicon {
                        alt
                        url
                      }
                      description {
                        html
                        text
                        raw
                      }
                      bingbot {
                        html
                        text
                        raw
                      }
                    }
                    slice_type
                  }
                  ... on PrismicBlogBody2OpenGraph {
                    primary {
                      og_url {
                        url
                        type
                        link_type
                      }
                      og_type {
                        html
                        text
                        raw
                      }
                      og_title {
                        html
                        text
                        raw
                      }
                      og_site_name {
                        html
                        text
                        raw
                      }
                      og_locale {
                        html
                        text
                        raw
                      }
                      og_image_width {
                        html
                        text
                        raw
                      }
                      og_image_height {
                        html
                        text
                        raw
                      }
                      og_image {
                        alt
                        url
                      }
                      og_description {
                        html
                        text
                        raw
                      }
                    }
                    slice_type
                  }
                  ... on PrismicBlogBody2TwitterCard {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      image {
                        alt
                        url
                      }
                      description {
                        html
                        text
                        raw
                      }
                    }
                    slice_type
                  }
                  ... on PrismicBlogBody2PinterestProductPin {
                    slice_type
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      description {
                        html
                        text
                        raw
                      }
                      price
                      currency
                      availability
                    }
                  }
                }
              }
            }
          }
        }
        allPrismicBlogDetail {
          group(field: tags) {
            fieldValue
            totalCount
          }
          nodes {
            data {
              image {
                url
              }
              title {
                html
                text
                raw
              }
              author {
                text
                html
                raw
              }
              release_date
              open_new_tab
            }
            uid
            tags
          }
        }
      }
      `
  )

  return {
    allPrismicBlog,
    allPrismicBlogDetail
  }
}