import React from "react";
import styled from "styled-components";
import { color } from "../../shared/style";
import PropTypes from "prop-types";
import { SubscribeForm } from "../../components/SubscribeForm/SubscribeForm";

const data = {
  title: {
    text: "STORIES AND NEWS IN YOUR INBOX",
  },
  description: {
    text: "Sign up to get a monthly mailing with top stories, news, and events.",
  },
  button: {
    text: "Send",
  },
};

export const Subscribe = () => {
  const { title, description, button } = data;
  if (!title || !description || !button) return null;
  return (
    <WrapperSection>
      <Action>
        <SubscribeForm
          title={title}
          description={description}
          button={button}
        />
      </Action>
    </WrapperSection>
  );
};

Subscribe.propTypes = {
  title: PropTypes.string,
};
const Action = styled.div`
  @media (max-width: 800px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const WrapperSection = styled.div`
  background-color: ${color.primary.blue};
  max-width: 1500px;
  padding: 80px 131px;
  box-sizing: border-box;
  margin-top: 100px;
  @media (max-width: 1499px) {
    padding: 80px 8.4vw;
  }
  @media (max-width: 650px) {
    padding: 70px 20px;
    flex-direction: column;
  }
`;
