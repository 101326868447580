import React from 'react'
import { ThemeProvider } from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Sidebar } from '../components/Sidebar';
import { BlogContainer } from "../containers/BlogContainer/BlogContainer";
import FooterContainer from '../containers/FooterContainer';
import { Header } from '../containers/HeaderContainer';
import useApp, { withAppContext } from '../contexts/context'
import { useQueryBlog } from '../hooks/useQueryBlogs';
import { useSiteMetadata } from '../hooks/useQuerydata';

const Blog = () => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();

  const { allPrismicBlog, allPrismicBlogDetail } = useQueryBlog();
  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const theme = {
    bgColor: 'white',
  }
  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer data={allPrismicFooter?.nodes[0]?.data} />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={
          <SEO data={allPrismicBlog?.edges[0]?.node?.data?.body2} />
        }
      >
        <BlogContainer data={allPrismicBlog?.edges[0]?.node?.data} dataTag={allPrismicBlogDetail} />
      </Layout>
    </ThemeProvider>
  )
}

export default withAppContext(Blog);
