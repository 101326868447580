

import React from "react";
import styled from "styled-components";
import { color } from "../../shared/style";
import single from "../../images/single-neutral.svg";
import stopwatch from "../../images/stopwatch.svg";
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
export const Author = ({ data, isFeed, isContent, onClick }) => {
  // const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const dateBeShow = new Date(data.date || "")
  const dd = String(dateBeShow.getDate())?.padStart(2, '0');
  const mm = monthNames[dateBeShow.getMonth()]?.substr(0, 3);
  const yyyy = dateBeShow?.getFullYear();
  return (
    <AuthorWrapper onClick={onClick}>
      <AuthorName>
        <AuthorIcon src={single} />{data.name}
      </AuthorName>
      <Divider isFeed={isFeed} isContent={isContent}>|</Divider>
      <AuthorTime>
        <AuthorIcon src={stopwatch} />{dateBeShow.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {dd} {mm} {yyyy}
      </AuthorTime>
    </AuthorWrapper>)
}
const AuthorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `
const AuthorIcon = styled.img`
    padding-right: 4px;
    max-width: 14px;
    max-height: 14px;
    width: 100%;
    height: 100%;
  `
const Divider = styled.div`
    line-height: 25px;
    padding-right: 10px;
    @media (max-width: 1155px) and (min-width: 651px) {
      ${props => props.isFeed && `
      opacity: 0;
      `}
    }
    @media (max-width: 1198px) and (min-width: 651px) {
      ${props => props.isContent && `
      opacity: 0;
      `}
    }
  `
const AuthorName = styled.div`
    color: ${color.dark.dark100};
    padding-right: 10px;
    font-size: 14px;
  `
const AuthorTime = styled.div`
    color: ${color.netraul.black70};
    font-size: 14px;
  `