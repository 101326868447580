import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import { Link } from "../../components/Link/Link";
import { Author } from "../../components/AuthorBlog/Author";
import { isMobile } from "../../hooks/checkMobile"

export const DSHBlogs = ({ data }) => {
  const stories_and_news = data?.stories_and_news;

  const content = stories_and_news && stories_and_news[0]?.post?.document?.data?.body?.find((item) => item.__typename === "PrismicBlogDetailBodyText");

  const RedirectToDetail = (url) => {
    window.location.href = `/${url}`
  }
  // console.log(stories_and_news[0]?.post?.document?.data)
  if (!data) return null;
  return (
    <WrapperSection>
      <WrapperHeadTitle>
        <HeadTitle tag={isMobile ? "h3" : "h4"} weight={500} capital="uppercase" color={color.primary.blue}>
          {data?.title?.text}
        </HeadTitle>
      </WrapperHeadTitle>
      <WrapperContent>
        <Main>
          <WrapperImage isMain>
            <Image src={stories_and_news[0]?.post?.document?.data?.image?.url} />
          </WrapperImage>
          <Featured>Featured</Featured>
          <WrapperTitle>
            <Title tag="h4" color={color.netraul.black100} capital="uppercase">{stories_and_news[0]?.post?.document?.data?.title?.text}</Title>
          </WrapperTitle>
          <WrapperDescription>
            <Description font={typography.type.primary} color={color.netraul.black80}>{content?.primary?.text?.text}</Description>
          </WrapperDescription>
          <Author data={{
            date: stories_and_news[0]?.post?.document?.data?.release_date,
            name: stories_and_news[0]?.post?.document?.data?.author?.text
          }} />
          <WrapperBtn>
            <Btn onClick={() => RedirectToDetail(stories_and_news[0]?.post?.document?.uid)}>Read more</Btn>
          </WrapperBtn>
        </Main>
        <Side>
          {
            stories_and_news?.slice(1, 3).map((item, i) => (
              <WrapperSide i={i} key={i}>
                <WrapperItemImage>
                  <Image src={item?.post?.document?.data?.image?.url} />
                </WrapperItemImage>
                <WrapperItemTitle>
                  <Title font={typography.type.primary} weight={typography.weight.bold} color={color.netraul.black100}>{item?.post?.document?.data?.title?.text}</Title>
                </WrapperItemTitle>
                <Author isFeed data={{
                  date: item?.post?.document?.data?.release_date,
                  name: item?.post?.document?.data?.author?.text
                }} />
                <WrapperBtnItem>
                  <BtnItem onClick={() => RedirectToDetail(item?.post?.document?.uid)}>Read more</BtnItem>
                </WrapperBtnItem>
              </WrapperSide>
            ))
          }
        </Side>
      </WrapperContent>
    </WrapperSection>
  );
};

DSHBlogs.propTypes = {
  title: PropTypes.string,
};

const WrapperSide = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.i === 0 && `
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(28, 27, 24, 0.1);;
    margin-bottom: 20px;
  `}
`
const Btn = styled(Link)`
  color: ${color.primary.blue};
  text-decoration: underline;
  text-underline-offset: 5px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
`
const WrapperBtn = styled.div`
  margin: 20px 0 40px 0;
`
const WrapperBtnItem = styled.div`
  margin-top: 16px;
`
const BtnItem = styled(Link)`
  color: ${color.primary.blue};
  text-decoration: underline;
  text-underline-offset: 5px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
`
const WrapperImage = styled.div`
  width: 100%;
  max-height: 431px;
  height: 100%;
  margin-bottom: 14px;
  @media (max-width: 650px) {
    height: 184px;
  }
`
const WrapperItemImage = styled.div`
  width: 100%;
  height: 200px;
  margin-bottom: 24px;
  @media (max-width: 650px) {
    height: 217px;
  }
`
const WrapperItemTitle = styled.div`
  margin: 16px 0;
`
const WrapperTitle = styled.div`
  margin-bottom: 20px;
`
const WrapperDescription = styled.div`
  margin-bottom: 20px;
`
const Featured = styled.div`
  background-color: #C2D7EB;
  width: fit-content;
  padding: 3px 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${color.primary.blue};
  margin-bottom: 8px;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
`
const WrapperSection = styled.div`
  padding: 0 130px;
  padding-top: 90px!important;
  max-width: 1500px;
  box-sizing: border-box;
  background-color: ${color.primary.blue05};
  padding-bottom: 80px;
  @media (max-width: 1000px) {
    padding: 0 40px;
    padding-bottom: 80px;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
    padding-bottom: 80px;
  }
`;
const HeadTitle = styled(Text)`
  letter-spacing: -0.015em;
`
const Description = styled(Text)`
  letter-spacing: -0.015em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
const Title = styled(Text)`
  letter-spacing: -0.015em;
  @media (max-width: 650px) {
    font-size: 20px!important;
    line-height: 30px;
  }
`
const WrapperHeadTitle = styled.div`
  margin: 40px 0;
  @media (max-width: 650px) {
    margin: 60px 0 40px 0;
  }
`
const WrapperContent = styled.div`
  display: flex;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`
const Main = styled.div`
  width: 63%;
  @media (max-width: 650px) {
    width: 100%;
  }
`
const Side = styled.div`
  display: flex;
  flex-direction: column;
  width: 37%;
  padding-left: 90px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    padding-left: 45px;
  }
  @media (max-width: 650px) {
    width: 100%;
    padding-left: 0;
  }
`