import React from 'react'
import styled from "styled-components";
import { DSHBlogs } from "./DSHBlogs"
import { Recent } from "./Recent"
import { Subscribe } from "./Subscribe"
// const RenderSpace = ({ type, data }) => {
//   switch (type) {
//     default:
//       return <></>
//   }
// }

export const BlogContainer = ({ data, dataTag }) => {
  return (
    <WrapperLayout>
      {/* {data && data?.map((item, i) => {
        return <RenderSpace key={i} type={item.slice_type} data={item} />
      })} */}
      <DSHBlogs data={data} />
      <Recent
        tags={dataTag?.group}
        data={data?.body[0] === undefined ? {} : data?.body[0]}
        allBlog={dataTag?.nodes}
      />
      <Subscribe />
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
`;
