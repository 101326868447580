import React from "react";
import styled from "styled-components";
import { color, typography } from "../../../shared/style";
// import PropTypes from "prop-types";
import { Text } from "../../../components/Text/Text";

export const BlogTags = ({ tags }) => {
    const redirectToTag = (tag, count) => {
        window.history.pushState({ count: count, tag: tag }, '', `/tags?tag=${tag}`);
        window.location.href = `/tags?tag=${tag}`;
    }
    if (!tags) return null;
    return (
        <WrapperSection>
            {
                tags?.map((item, i) => (
                    <Item key={i} onClick={() => redirectToTag(item.fieldValue, item.totalCount)}>
                        <WrapperName>
                            <Name font={typography.type.primary} color={color.primary.blue} capital="uppercase" weight={typography.weight.bold}>{item.fieldValue}</Name>
                        </WrapperName>
                        <Quantity font={typography.type.primary} color={color.primary.blue60} capital="uppercase" weight={typography.weight.bold}>{item.totalCount}</Quantity>
                    </Item>
                ))
            }
        </WrapperSection>
    );
};


// Tags.propTypes = {
//     title: PropTypes.string,
// };
const Name = styled(Text)`
    font-size: 12px !important;
    line-height: 22px;
    cursor: pointer;
`
const WrapperName = styled.div`
    padding-right: 10px;
`
const Quantity = styled(Text)`
    font-size: 12px !important;
    line-height: 22px;
    cursor: pointer;
`
const Item = styled.div`
    width: fit-content;
    align-items: baseline;
    display: flex;
    flex-direction: row;
    padding: 5px 14px;
    background-color: ${color.primary.blue10};
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    cursor: pointer;
`
const WrapperSection = styled.div`
    margin-top: 12px;
    margin-left: -10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
`;